<!--<template>
    <div class="d-flex flex-column flex-root">
        <div
            class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
            :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot'
      }"
            id="kt_login"
        >
            &lt;!&ndash;begin::Aside&ndash;&gt;
            &lt;!&ndash;begin::Aside&ndash;&gt;
            &lt;!&ndash;begin::Content&ndash;&gt;
            <div
                class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
            >
                <div class="d-flex flex-column-fluid flex-center">
                    &lt;!&ndash;begin::Signin&ndash;&gt;
                    <div class="login-form login-signin">
                        <form @submit.stop.prevent="sendOtp" autocomplete="off"
                              class="form"
                              novalidate="novalidate"
                              id="kt_login_signin_form"

                        >
                            <div class="pb-13 pt-lg-0 pt-5">
                                <h3
                                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                                >
                                    Welcome to House of Banquets
                                </h3>
                            </div>
                            <div class="form-group">
                                <label class="font-size-h6 font-weight-bolder text-dark">Phone</label>
                                <div id="example-input-group-1" label="" label-for="example-input-1">
                                    <input
                                        :disabled="otp ? true :false"
                                        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                                        type="text"
                                        name="phone"
                                        ref="phone"
                                        v-model="formFields.phone"
                                        v-numericOnly.keyup
                                    />
                                </div>
                                <div
                                    role="alert"
                                    :class="{ show: errors.phone }"
                                    class="mt-1 alert fade alert-danger"
                                >
                                    <div class="alert-text" v-for="(error, i) in errors.phone" :key="i">
                                        {{ error }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" v-if="otp">
                                <div class="d-flex justify-content-between mt-n5">
                                    <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                                    >Verify OTP</label
                                    >
                                </div>
                                <div
                                    id="example-input-group-2"
                                    label=""
                                    label-for="example-input-2"
                                >
                                    <input
                                        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                                        type="password"
                                        name="otp"
                                        ref="otp"
                                        v-model="formFields.otp"
                                        autocomplete="off"
                                    />
                                </div>
                                <div
                                    role="alert"
                                    v-bind:class="{ show: errors.otp }"
                                    class="mt-1 alert fade alert-danger"
                                >
                                    <div class="alert-text" v-for="(error, i) in errors.otp" :key="i">
                                        {{ error }}
                                    </div>
                                </div>
                            </div>
                            <div class="pb-lg-0 pb-5" v-if="otp">
                                <button
                                    @click.prevent="handleSubmitOperation"
                                    :disabled="loader && errors.length === 0"
                                    ref="kt_login_signin_submit"
                                    class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                                >
                                    <i class="fa fa-spin fa-spinner" v-if="loader && errors.length === 0"></i>
                                    &lt;!&ndash; /.fa fa-spin fa-spinner &ndash;&gt;
                                    Sign In
                                </button>
                                <button
                                    :disabled="time > 0"
                                    ref="kt_login_signin_submit"
                                    @click.prevent="sendOtp"
                                    class="btn btn-secondary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                                >
                                    <i class="fa fa-spin fa-spinner" v-if="loader && errors.length === 0"></i>
                                    &lt;!&ndash; /.fa fa-spin fa-spinner &ndash;&gt;
                                    Resend {{ ( time > 0 ? `(${ time })` : '' ) }}
                                </button>
                            </div>
                        </form>
                        <div class="pb-lg-0 pb-5" v-if="!otp">
                            <button
                                :disabled="loader && errors.length === 0"
                                @click.prevent="sendOtp"
                                ref="kt_login_signin_submit"
                                class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                            >
                                <i class="fa fa-spin fa-spinner" v-if="loader && errors.length === 0"></i>
                                &lt;!&ndash; /.fa fa-spin fa-spinner &ndash;&gt;
                                Send OTP
                            </button>
                        </div>
                    </div>
                    &lt;!&ndash;end::Signin&ndash;&gt;
                </div>
                &lt;!&ndash;begin::Content footer&ndash;&gt;
                <div
                    class="d-flex justify-content-center align-items-end py-7 py-lg-0 mt-5"
                >
                    <router-link class="text-primary ml-8 font-weight-bolder font-size-h6"
                                 to="/terms-and-condition">
                        Terms And Condition
                    </router-link>
                    <router-link class="text-primary ml-8 font-weight-bolder font-size-h6"
                                 to="/privacy-policy">
                        Privacy Policy
                    </router-link>
                    <router-link class="text-primary ml-8 font-weight-bolder font-size-h6"
                                 to="/data-production">
                        Data Protection Policy
                    </router-link>
                    <router-link class="text-primary ml-8 font-weight-bolder font-size-h6"
                                 to="/contact-us">
                        Contact Us
                    </router-link>
                </div>
                &lt;!&ndash;end::Content footer&ndash;&gt;
            </div>
            &lt;!&ndash;end::Content&ndash;&gt;
        </div>
    </div>
</template>-->
<template>
  <div class="body">
    <div :class="activeClass + ' container'" id="container">
      <div class="form-container sign-up-container right-panel-active">
        <form action="#">
          <h1>Sign in</h1>
          <span>enter OTP</span>
          <input type="password" placeholder="OTP" class="rounded-lg"
                 name="otp"
                 ref="otp"
                 v-model="formFields.otp"
                 autocomplete="off"/>

          <div
            role="alert"
            v-bind:class="{ show: errors.otp }"
            class="mt-1 mb-2 fade text-danger"
          >
            <div class="alert-text" v-for="(error, i) in errors.otp" :key="i">
              {{ error }}
            </div>
          </div>

          <button @click.prevent="handleSubmitOperation">
            <i class="fa fa-spin fa-spinner text-white" v-if="loader && errors.length === 0"></i><span v-else>Login</span>
          </button>
        </form>
      </div>
      <div class="form-container sign-in-container">
        <form action="#" @submit.stop.prevent="sendOtp">
          <h1>Sign in</h1>
          <span>using your phone</span>
          <input type="text" placeholder="Phone" class="rounded-lg"
                 name="phone"
                 ref="phone"
                 v-model="formFields.phone"
                 v-numericOnly.keyup/>

          <div
            role="alert"
            v-bind:class="{ show: errors.phone }"
            class="mt-1 mb-2 fade text-danger"
          >
            <div class="alert-text" v-for="(error, i) in errors.phone" :key="i">
              {{ error }}
            </div>
          </div>
          <button>
            <i class="fa fa-spin fa-spinner text-white" v-if="loader && errors.length === 0"></i><span v-else>Sign In</span>
          </button>
        </form>
      </div>
      <div class="overlay-container">
        <div class="overlay">
          <div class="overlay-panel overlay-left">
            <h1>Welcome Back!</h1>
            <p>Please enter OTP you received on your phone</p>
            <button class="ghost" id="signIn" @click="handleGoBack">Back</button>
          </div>
          <div class="overlay-panel overlay-right">
            <h1>Welcome to House of Banquets!</h1>
            <p>Enter your mobile number and start journey with us</p>
<!--            <button class="ghost" id="signUp">Sign Up</button>-->
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div
        class="d-flex justify-content-center align-items-end py-7 py-lg-0 mt-5"
      >
        <router-link class="ml-8"
                     to="/terms-and-condition">
          Terms And Condition
        </router-link>
        <router-link class="ml-8"
                     to="/privacy-policy">
          Privacy Policy
        </router-link>
        <router-link class="ml-8"
                     to="/data-production">
          Data Protection Policy
        </router-link>
        <router-link class="ml-8"
                     to="/contact-us">
          Contact Us
        </router-link>
      </div>
    </footer>
  </div>
</template>
<!-- Load login custom page styles -->
<style lang="scss" scoped>
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import KTUtil from "@/assets/js/components/util";
import {mapGetters, mapState} from "vuex";
import {LOGIN, SENDOTP} from "@/core/services/store/auth.module";

const DEFAULT_DEFAULT_FORM_STATE = {
  phone: null,
  otp: null,
  isAdmin: false,
};

const DEFAULT_OTP_DEFAULT_FORM_STATE = {
  opt: null,
};

export default {
  name: "login-1",
  data() {
    return {
      activeClass: '',
      loader: false,
      formFields: {...DEFAULT_DEFAULT_FORM_STATE},
      otpFields: {...DEFAULT_OTP_DEFAULT_FORM_STATE},
      state: "signin",
      otp: false,
      time: 0,
    };
  },
  mounted() {
    this.formFields.isAdmin = this.$route.name === 'admin-login'
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      getActiveProperty: 'getActiveProperty',
      getActiveEvent: 'getActiveEvent',
    }),
    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  },
  methods: {
    countDownTimer() {
      if (this.time > 0) {
        setTimeout(() => {
          this.time -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    sendOtp() {
      this.loader = true;
      this.time = 60;

      this.countDownTimer();

      this.$store
        .dispatch(SENDOTP, this.formFields)
        .then((response) => {
          this.otp = true;
          this.loader = false;
          this.activeClass = 'right-panel-active'
        })
        .catch((error) => {
          this.loader = false;
        });
    },
    handleGoBack() {
      this.otp = false;
      this.activeClass = ''
      this.formFields.otp = null;
    },
    handleSubmitOperation() {
      localStorage.removeItem('property_id');
      localStorage.removeItem('event_id');
      this.loader = true;
      let self = this;
      // set spinner to submit button
      this.$store
        .dispatch(LOGIN, this.formFields)
        .then((response) => {
          self.loader = false;
          if (this.$global.hasPermission('eventsview')) {
            this.$router.push({name: "events"});
          } else {
            this.$router.push({name: "dashboard"});
          }
        })
        .catch((error) => {
          this.loader = false;
        });
    },
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    }
  }
};
</script>
